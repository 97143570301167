import React, {Component} from 'react';
import { authToken } from './services/Authentication';
import { getStations, updateStations } from './services/Stations';

const AppStateContext = React.createContext();

export class AppStateProvider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      token: '',
      wholesaler: {},
      stations: [],
    };
  }

  async authenticate(token) {
    return new Promise(async (resolve, reject) => {
      const result = await authToken(token);
      if(result) {
        this.setState({token, wholesaler: result});
        resolve(true);

        const stations = await getStations(token);
        console.log(stations);
        this.setState({stations});

      } else {
        resolve(false);
      }
    })
    
  }

  updateStation(id_location, registered) {
    console.log(id_location, registered);
    const tempStations = [...this.state.stations];

    for(let i=0; i < tempStations.length; i++) {
      if(tempStations[i].id_location === id_location) {
        tempStations[i].registered = registered;
        break;
      }
    }

    this.setState({stations: tempStations});
  }

  saveStations() {
    return new Promise(async (resolve, reject) => {
      const result = await updateStations(this.state.token, this.state.stations);
      console.log(result);
      resolve(result);
    })
  }

  render() {
    return (
      <AppStateContext.Provider
        value={{

          // authentication
          token: this.state.token,
          authenticate: (token) => this.authenticate(token),

          wholesaler: this.state.wholesaler,
          stations: this.state.stations,
          updateStation: (id_location, registered) => this.updateStation(id_location, registered),
          saveStations: () => this.saveStations(),
          
        }}>
        {this.props.children}
      </AppStateContext.Provider>
    );
  }
}

export default AppStateContext;
