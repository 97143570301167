import React, {useContext, useState} from 'react';
import { makeStyles } from '@material-ui/styles';
import { useHistory } from "react-router-dom";
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Switch from '@material-ui/core/Switch';
import Snackbar from '@material-ui/core/Snackbar';
import TextField from '@material-ui/core/TextField';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import AppStateContext from '../AppStateContext';
import toTitleCase from 'titlecase';

import Copyright from '../components/Copyright/Copyright';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

export default function Stations() {
  const classes = useStyles();
  const context = useContext(AppStateContext);
  const history = useHistory();

  const [savedMessageOpen, setSavedMessageOpen] = useState(false);

  const [filter, setFilter] = useState('');

  const [stationsUpdated, setStationsUpdated] = useState(0);

  const saveStations = async (finish) => {
    const result = await context.saveStations();

    setStationsUpdated(result.stations_changed);
    if(finish) {
      history.push('/finish');
    } else {
      setSavedMessageOpen(true);
    }
  }

  return (
    <AppStateContext.Consumer>
      {(appState) => (
        <React.Fragment>
          <main className={classes.layout}>
            <Paper className={classes.paper} elevation={0}>
                <Typography component="h1" variant="h4" align="center" gutterBottom>
                  {appState.wholesaler.name}
                </Typography>
                <Typography component="p" variant="body1" align="center" gutterBottom>
                  Select the enrollment status for the stations and save at the end.
                </Typography>

                <TextField style={{width: '100%'}} id="outlined-basic" label="Filter Address..." variant="outlined" onChange={(event)=>setFilter(event.target.value)}/>

                  <List className={classes.root}>
                    
                    {appState.stations.map(station => {
                        const re = new RegExp(filter, 'i');

                        if(station.street_address.match(re) || station.city_name.match(re) || station.zip_code.match(re)) {

                          return(
                            <ListItem>
                              <ListItemAvatar>
                                <Avatar alt="Shell Logo" src="/images/Shell_logo.svg" />
                              </ListItemAvatar>
                              <ListItemText
                                primary={toTitleCase(station.street_address.toLowerCase())}
                                secondary={
                                  <React.Fragment>
                                    <Typography
                                      component="span"
                                      variant="body2"
                                      className={classes.inline}
                                      color="textPrimary"
                                    >
                                      {toTitleCase(station.city_name.toLowerCase())}
                                      {' '}
                                      {station.state}
                                      {' '}
                                      {station.zip_code}
                                    </Typography>
                                    
                                  </React.Fragment>
                                }
                              />
                              <ListItemSecondaryAction>
                                    <FormControlLabel
                                      value="right"
                                      control={
                                        <Switch
                                          color="primary"
                                          edge="end"
                                          onChange={()=>appState.updateStation(station.id_location, !station.registered)}
                                          checked={!!station.registered}
                                        />
                                      }
                                      style={!!station.registered ? {color: 'green'} : {color: 'darkgray'}}
                                      label={!!station.registered ? 'Enrolled' : 'Opted out'}
                                      labelPlacement="start"
                                    />
                              </ListItemSecondaryAction>
                            </ListItem>);

                        } else {
                          return(undefined);
                        }
                    })}
                  </List>
                
             
                
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                    {/* <Button
                            variant="contained"
                            color="primary"
                            align="center"
                            onClick={()=>saveStations()}
                            className={classes.button}
                            size="large"
                        >
                            Save Changes
                    </Button> */}
                    <Button
                            variant="contained"
                            color="primary"
                            align="center"
                            onClick={()=>saveStations(true)}
                            className={classes.button}
                            size="large"
                        >
                            Submit Changes
                    </Button>
                </div>
                <Typography variant="body2" align="center" style={{marginTop: '10px'}}>
                  You can always come back later to make more changes. 
                </Typography>
                

                <Snackbar message={"Saved, " + stationsUpdated + (stationsUpdated === 1 ? ' station' : ' stations') + " updated"} open={savedMessageOpen} autoHideDuration={4000} onClose={()=>setSavedMessageOpen(false)} />
                  
              
            </Paper>
            <Copyright />
          </main>
        </React.Fragment>
      )}
    </AppStateContext.Consumer>
  );
}