import hostname from './hostname';

export const authToken = (token) => {
    return new Promise(async (resolve, reject) => {
        fetch(`https://${hostname}/api/shellUSWholesalers/auth`,{
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
              'Content-Type': 'application/json'
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: JSON.stringify({token}) // body data type must match "Content-Type" header
          })
        .then(response => response.json())
        .then(data => resolve(data))
        .catch(err => {
          // console.log('error from the server');
          resolve(false);
        })
    });
}