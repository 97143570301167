import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import Copyright from '../components/Copyright/Copyright';
import LogoHero from '../components/LogoHero/LogoHero';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
}));


export default function Unauthenticated() {
  const classes = useStyles();

  return (
          <main className={classes.layout}>
            <Paper className={classes.paper} elevation={0}>
              <LogoHero />
              
              <Typography component="h1" variant="h4" align="center" style={{marginTop: '30px'}}>
                Account Not Found
              </Typography>
              <Typography variant="subtitle1">
                  Please use the link from the enrollment email to access the enrollment system. If you cannot find this link any more please contact:
                  <ul>
                      <li><strong>SOPUS</strong> - <a href="mailto:Shannon.Hubbard@shell.com?subject=FuelService Wholesaler Registration">Shannon Hubbard</a></li>
                      <li><strong>Motiva</strong> - <a href="mailto:Stella.McClellan@Motiva.com?subject=FuelService Wholesaler Registration">Stella McClellan</a></li>
                  </ul>
              </Typography>
            </Paper>
            <Copyright />
          </main>
  );
}