import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { useHistory } from "react-router-dom";
import Paper from '@material-ui/core/Paper';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import Copyright from '../components/Copyright/Copyright';
import AppStateContext from '../AppStateContext';
import toTitleCase from 'titlecase';
import LogoHero from '../components/LogoHero/LogoHero';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

export default function Finish() {
  const classes = useStyles();
  const history = useHistory();

  return (
    <AppStateContext.Consumer>
      {(appState) => (
        <React.Fragment>
          <main className={classes.layout}>
            <Paper className={classes.paper} elevation={0}>
                <LogoHero />
                <Typography component="h1" variant="h4" align="center" gutterBottom>
                  Thank you
                </Typography>
                <Typography component="h1" variant="h4" align="center" gutterBottom>
                  {appState.wholesaler.name && toTitleCase(appState.wholesaler.name.toLowerCase())}
                </Typography>
             
                <React.Fragment>
                    <Typography variant="subtitle1" gutterBottom>
                      You will receive an email shortly with details of the next steps which include:
                        <ol>
                            <li>Cashier Training tools</li>
                            <li>Details of the content and delivery of POP kits.</li>
                            <li>Go-live schedule for your sites on the fuelService app</li>
                        </ol>
                    </Typography>
                    <Typography variant="subtitle1" gutterBottom>
                      In the interim please view the 9 minute video on what fuelService is and how it is deployed&nbsp;
                      <a href="https://shell-training.fuelservice.org" target="_blank" rel="noreferrer">here</a>.
                    </Typography>
                    <Typography variant="subtitle1" gutterBottom>
                    If you have additional questions about the enrollment process, please contact:
                    <ul>
                        <li><strong>SOPUS</strong> - <a href="mailto:Shannon.Hubbard@shell.com?subject=FuelService Wholesaler Registration">Shannon Hubbard</a></li>
                        <li><strong>Motiva</strong> - <a href="mailto:Stella.McClellan@Motiva.com?subject=FuelService Wholesaler Registration">Stella McClellan</a></li>
                    </ul>
                    <strong>For General Questions about fuelService please contact:</strong> <a href="mailto:support@fuelservice.org?subject=FuelService Wholesaler Registration Question">FuelService</a>
                    </Typography>
                </React.Fragment>
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', marginBottom: '10px'}}>
                    <Button
                            variant="contained"
                            color="primary"
                            align="center"
                            onClick={()=>history.push("/stations")}
                            className={classes.button}
                            size="large"
                        >
                            Make further changes
                    </Button>
                </div>
                <Typography variant="body2">
                  *To make enrollment changes at a later date, please use the link provided in your enrollment email.
                </Typography>
              
            </Paper>
            <Copyright />
          </main>
        </React.Fragment>
      )}
    </AppStateContext.Consumer>
  );
}