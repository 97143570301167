import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom';

import { AppStateProvider } from './AppStateContext';

import MainAppBar from './components/MainAppBar/MainAppBar';

import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Home from './home/Home';
import Done from './done/Done';
import Stations from './stations/Stations';
import Unauthenticated from './unauthenticated/Unauthenticated';
import Finish from './finish/Finish';

function App() {
  return (
    <AppStateProvider>
      <Router>
          <MainAppBar />
          <Switch>
            <Route path="/stations">
              <Container maxWidth="sm">
                <Box sx={{ my: 4 }}>
                  <Stations />
                </Box>
              </Container>
            </Route>

            <Route path="/done">
              <Container maxWidth="sm">
                <Box sx={{ my: 4 }}>
                  <Done />
                </Box>
              </Container>
            </Route>

            <Route path="/unauthenticated">
              <Container maxWidth="sm">
                <Box sx={{ my: 4 }}>
                  <Unauthenticated />
                </Box>
              </Container>
            </Route>
            
            <Route path="/login/:token([a-zA-Z0-9]{32,})">
              <Home />
            </Route>

            <Route path="/finish">
              <Finish />
            </Route>
            
            <Route path="/">
              <Unauthenticated />
            </Route>
          </Switch>

        </Router>
      </AppStateProvider>
  );
}

export default App;
