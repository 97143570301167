import { blue, red } from '@material-ui/core/colors';
import { createTheme } from '@material-ui/core/styles';

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: '#da291c',
      // main: 'rgb(250, 205, 48)', // shell yellow
      // main: '#dd1d21',
      contrastText: '#ffffff'
      // contrastText: 'rgb(64, 64, 64)'
    },
    secondary: {
      main: 'rgb(250, 205, 48)', // shell yellow
      contrastText: 'rgb(64, 64, 64)' // shell grey
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#fff',
    },
  },
  components: {
    MuiListItemButton: {
      // follow https://material-ui.com/r/pseudo-classes-guide
      styleOverrides: {
        // Name of the slot
        "root": {
          "&.Mui-selected": {
            "backgroundColor": blue[200]
          },
          "&.Mui-selected:hover": {
            "backgroundColor": blue[200]
          }
        }
      },
    },
  },
});

export default theme;