import React, { useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/styles';
import { useHistory, useParams } from "react-router-dom";
import Paper from '@material-ui/core/Paper';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import Copyright from '../components/Copyright/Copyright';
import AppStateContext from '../AppStateContext';
import toTitleCase from 'titlecase';
import LogoHero from '../components/LogoHero/LogoHero';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 1000,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

export default function Home() {
  const classes = useStyles();
  const history = useHistory();

  const {token} = useParams();

  const context = useContext(AppStateContext);

  useEffect(()=>{
    async function verifyToken() {
      console.log(token);
      const result = await context.authenticate(token);
      if(!result) {
        history.push('/unauthenticated')
      }
    }
    verifyToken();
    // eslint-disable-next-line
  },[]);

  return (
    <AppStateContext.Consumer>
      {(appState) => (
        // <React.Fragment>
          <main className={classes.layout}>
            <Paper className={classes.paper} elevation={0}>
              <LogoHero />

              <Typography component="h1" variant="h5" align="center" gutterBottom>
                Welcome {appState.wholesaler.name && toTitleCase(appState.wholesaler.name.toLowerCase())}
              </Typography>
              <Typography component="h1" variant="h4" align="center" gutterBottom>
                Helping you, help your customers with disabilities to fill up
              </Typography>
             
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginBottom: '0px', marginTop: '20px'}}>
                    <div>
                      <img style={{width: '400px', marginRight: '20px'}} alt="Shell Logo" src='/images/iPhone-6s-Silver-vertical.png' />
                    </div>
                    <div>
                      {/* <Typography variant="h6" gutterBottom> */}
                      <div style={{fontSize: '1.3rem', marginBottom: '16px'}}>
                      Shell has partnered with fuelService to enhance the customer journey for drivers with disabilities. fuelService is free and easy to use for drivers and brings them to Shell stations through a seamless process.
                      </div>
                      <div style={{fontSize: '1.3rem', marginBottom: '16px'}}>
                      The Americans with Disabilities act requires self-service gas stations to provide equal access to their customer with disabilities.
                      <br />
                      In the past, this assistance was given through beeping horns, flashing lights and hoping for assistance.
                      <br />
                      With fuelService, drivers can use a smartphone app to contact the station.
                      <br />
                      It’s simple to deploy and use, the cashiers just need to answer the phone and respond to an automated message.
                      </div>
                      <div style={{fontSize: '1.3rem', marginBottom: '16px'}}>
                        <a href="https://shell-training.fuelservice.org" target="_blank" rel="noreferrer">Watch</a> a quick overview of the program&nbsp;
                        <a href="https://shell-training.fuelservice.org" target="_blank" rel="noreferrer">here</a>
                      </div>
                      <div style={{fontSize: '1.3rem', marginBottom: '16px'}}>
                      There is <strong>NO cost</strong> for stations to use fuelService. Shell is covering all costs for wholesalers and dealers. All sites are automatically enrolled in fuelService, all you have to do is opt-out any sites not participating and fuelService will take care of the rest!
                      </div>
                    </div>
                </div>
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', marginBottom: '10px'}}>
                    <Button
                            variant="contained"
                            color="primary"
                            align="center"
                            onClick={()=>history.push("/stations")}
                            className={classes.button}
                            size="large"
                        >
                            Complete Station Enrollment
                    </Button>
                </div>
                <Typography variant="body2" align="center">
                  *By default, all stations are enrolled in the fuelService program. Click the button above to opt-out enrolled stations. 
                </Typography>
              
            </Paper>
            <Copyright />
          </main>
        // </React.Fragment>
      )}
    </AppStateContext.Consumer>
  );
}